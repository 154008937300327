.navbar{
background: #1A1A1A;
height: 5rem;
width: 100vw;
max-width: 75rem;
margin: 0 auto; 
padding: 1rem 2rem;
display: flex;
align-items: center; 
justify-content: space-between;
position: sticky;
top: 0;
z-index: 3;
}

.logo{
    font-size: 1.5rem;
    font-weight: 600;
    color: #94AF9F;
    cursor: default;
}

.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
    font-size: 1.0rem;
}

.desktopMenuListItem:hover{
    color: #94AF9F;
    padding-bottom: 0.5rem;
    border-bottom: 8px solid #94AF9F;
}

.desktopMenuBtn{
    background: #94AF9F;
    color: white;
    border: none;
    height: 3rem;
    width: 8rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
    cursor: pointer;
}

.active{
    color: #94AF9F;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #94AF9F;
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem
}

.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: #1A1A1A;
    border-radius: 1rem;
}

.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: #272829;
}

@media screen and (max-width: 720px){
    .mobMenu{
        display: flex;
    }

    .desktopMenu{
        display: none;
    }

    .desktopMenuBtn{
        display: none;
    }
}