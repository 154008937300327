#skills{
overflow: hidden;
width: 100vw;
max-width: 65rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
margin: 0 auto;
}

.skillTitle{
font-size: 3rem;
font-weight: 600;
padding-top: 3rem;
margin-bottom: 1.5rem;
text-align: justify;
}

.skillBars{
margin: 1.5rem;
width: 100vw;
max-width: 50%;
display: grid;
grid-template-rows: 180px 180px 180px;
grid-template-columns: 180px 180px 180px;
justify-content: center;
text-align: left;
}

.skillBar{
display: flex;
margin: 1rem;
padding: 1.5rem 2rem;
border-radius: 0.5rem;
background: #272829;
box-shadow:  0 0 10px #61677A;
}

.skillBarImg{
align-items: center;
height: 5rem;
width: 6rem;
border-radius:  0.4rem;
}

.skillBarText>p {
    font-size: 0.9rem;
    font-weight: 200;
}

@media screen and (max-width: 720px){
    .skillBarText>p {
        font-size: 2vw;
    }
}

@media screen and (max-width: 480px){
    .skillBarText>p {
        font-size: 3vw;
    }
    .skillBarImg{
        height: 2.25rem;
        width: 2.25rem;
    }
    .skillBarText>h2{
        font-size: 5vw;
    }
}