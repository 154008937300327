#works{
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle{
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc{
   font-weight: 300;
   font-size: 1rem;
   max-width: 45rem;
   text-align: left;
}

.worksImgs{
 display: flex;
 align-items: center;
 justify-content: center;
 flex-wrap: wrap;
 width: 100vw;
 max-width: 65rem;
}

.worksImg{
    object-fit: cover;
    height: 15rem;
    margin: 0.5rem;
    border-radius: 1.8rem;
    display: block;
    transition: opacity 1s ease;
}

.worksImg:hover {
 opacity: 0.2;
}

.card1Img:hover{
     object-fit: cover;
     background: url(../../assets/SwiftSchatten.png);
     background-size: 450px;
     height: 15rem;
     border-radius: 1.8rem;
}

.card2Img:hover{
     object-fit: cover;
     background: url(../../assets/SwiftSchatten.png);
     background-size: 450px;
     height: 15rem;
     border-radius: 1.8rem;
}

.card3Img:hover{
     object-fit: cover;
     background: url(../../assets/SwiftSchatten.png);
     background-size: 450px;
     height: 15rem;
     border-radius: 1.8rem;
}

.card4Img:hover{
     object-fit: cover;
     background: url(../../assets/ReactSchatten.png);
     background-size: 450px;
     height: 15rem;
     border-radius: 1.8rem;
}

.card5Img:hover{
     object-fit: cover;
     background: url(../../assets/ReactSchatten.png);
     background-size: 450px;
     height: 15rem;
     border-radius: 1.8rem;
}

.card6Img:hover{
     object-fit: cover;
      background: url(../../assets/ReactSchatten.png);
     background-size: 450px;
     height: 15rem;
     border-radius: 1.8rem;
}

.workBtn{
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: #94AF9F;
    font-size: 1rem;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 585px){
    .worksImg{
        height: 48vw;
    }
}