#intro{
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.image2{
    position: absolute;
    top: 10rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    width: 33vw;
    border-radius: 50%;
    animation: floatImage 4s ease-in-out infinite;
}
   @keyframes floatImage {
        0%{
            transform: translateY(0);
        }
        50%{
            transform: translateY(-2.4rem);
        }
        100%{
            transform: translateY(0);
        }
    }

    .introContent{
        height: 100vh;
        width: 100vw;
        padding: 2rem;
        font-size: 3rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .hello{
        font-size: 1.75rem;
        font-weight: 100;
    }

    .introName{
        color:  #94AF9F;
    }

    .introPara{
        font-size: medium;
        font-weight: 300;
        letter-spacing: 1px;

    }

    @media screen and (max-widt: 840px){
        .image2{
            right: -10vw;
        }

        .introContent{
            font-size: 10vw;
        }
        .hello{
            font-size: 4.5vw;
        }
    }

     @media screen and (max-widt: 480px){
        .image2{
            right: -20vw;
        }
    }